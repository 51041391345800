import {request} from './request'
import qs from 'qs'
import Cookies from 'js-cookie'

export function getProductdemand() {
    let maker_id = Cookies.get('maker_id');
    return request({url:'/test/test.php?a=getProductdemand',params:{maker_id}});
}
export function editProductdemand(params){
    params.maker_id = Cookies.get("maker_id");
    return  request({url:`/test/test.php?a=editProductdemand`,data:qs.stringify(params),method:'post'});
}
export function setLogisticsInformation() {
    let maker_id = Cookies.get('maker_id');
    return request({url:'/test/test.php?a=setLogisticsInformation',params:{maker_id}});
}