<template>
  <div class="medialist">
    <a-page-header title="Product Demand" class="header_title" />
    <div class="box_content">
    
      <a-table :columns="columns" :data-source="data" :rowKey="(record, index) => index" size="middle"
        :loading="loading">
        <span slot="Products" slot-scope="text,data" class='td_black'>
          <img v-bind:src="data.img" style="width: 50px;float:left;margin-right:5px;" />
          <div style="float:left;">
            <p style="color: rgba(0, 0, 0, 0.85);">{{ data.Product }}</p>
            <p>Varlation:{{ data.Varlation }}</p>
            <p>SKU:{{ data.SKU }}</p>
          </div>
        </span>
        <span slot="Status" slot-scope="data">
          <a-tag v-if="data.Status == 1">Product preparation</a-tag>
          <a-tag v-if="data.Status == 2">Not signed</a-tag>
          <a-tag v-if="data.Status == 3">Sign for</a-tag>
        </span>
        <span slot="action" slot-scope="text">
          <a @click="showModal(text.Product)">Edit</a>
        </span>
      </a-table>
    </div>
    <a-modal v-model="visible" title="" on-ok="handleOk">
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading2" @click="handleOk">
          Submit
        </a-button>
      </template>
      <a-form layout="inline" :form="form">
        <a-form-item label="">
          <a-input v-decorator="[
            'str1',
            { rules: [{ required: true, message: 'Please input your Logistics company!' }] },
          ]" placeholder="Logistics company">
          </a-input>
        </a-form-item>
        <a-form-item label="">
          <a-input v-decorator="[
            'str2',
            { rules: [{ required: true, message: 'Please input your Courier number!' }] },
          ]" placeholder="Courier number">
          </a-input>
        </a-form-item>
      </a-form>
    </a-modal>

  </div>

</template>

<script>
const columns = [
  {
    title: 'Product',
    dataIndex: 'Product',
    key: 'Product',
    scopedSlots: { customRender: 'Products' },
  },
  {
    title: 'DemandQuantity',
    dataIndex: 'DemandQuantity',
    key: 'DemandQuantity',
  }, {
    title: 'LatestArrivalDate',
    dataIndex: 'LatestArrivalDate',
    key: 'LatestArrivalDate',
  },
  // {
  //   title: 'Shipment quantity',
  //   dataIndex: 'Quantity',
  //   key: 'Quantity',
  // },
  {
    title: 'Status',
    // dataIndex: 'Status',
    key: 'Status',
    scopedSlots: { customRender: 'Status' },
  },
  {
    title: 'LogisticsInformation',
    key: 'LogisticsInformation',
    dataIndex: 'LogisticsInformation',
    scopedSlots: { customRender: 'action' },
  },
];

import { getProductdemand, setLogisticsInformation } from '@/network/productdemand'
export default {
  name: 'Productdemand',
  components: {},
  directives: {},
  data() {
    return {
      loading: false,
      data: [],
      columns,
      loading: false,
      loading2: false,
      visible: false,
      form: this.$form.createForm(this, { name: 'user_from' }),
    };
  },
  created() {
    this.getList(1);
  },
  methods: {
    getList(page) {
      this.loading = true;
      getProductdemand(page).then(res => {
        console.log(res);
        this.loading = false;
        if (res.header.code == 200) {
          this.total_rows = res.body.total_rows
          this.data = res.body.detail
        }
      });
    },
    editBtn(e) {

    },
    changePage(current) {
      this.current = current;
      this.getList(current)
    },
    showModal(Product) {
      this.visible = true;
    },
    handleOk(e) {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading2 = true;
          console.info('success');
          console.log(values);
          setLogisticsInformation().then(res => {
            this.visible = false;
            this.loading2 = false;
          });
        }
      });
      // setTimeout(() => {
      //   this.visible = false;
      //   this.loading = false;
      // }, 3000);
    },
    handleCancel(e) {
      this.visible = false;
    }
  },
};
</script>

<style  scoped>
.mediaimg {
  width: 100%;
  height: 100%;
}

.delmediabtn {
  text-align: center;
  color: #fff;
  padding: 5px;
  background-color: rgb(190, 103, 103);
}
</style>